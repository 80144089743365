@import url('https://fonts.googleapis.com/css?family=Titillium+Web:regular,bold,italic');

$color-default: #727272;
$color-white: #fff;
$color-yellow: #ffa71c;
$color-yellow2: #ec9100;
$color-grey: #d7d7d7;
$color-grey2: #6e6e6e;
$color-grey3: #afafaf;
$color-grey4: #b2b2b2;
$color-grey5: #eaeaea;
$color-grey6: #575757;

$color-red: #ea0505;

body {
    font-family: 'Titillium Web';
    font-weight: 400;
    color: $color-default;
    font-size: 16px;
}

a,
input {
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    transition: all linear 0.3s;
}

h1,
h2,
h3 {
    color: #222;
}

h2 {
    font-size: 45px;
    font-weight: 600;

    .fa {
        font-size: 20px;
        color: $color-yellow;
        vertical-align: middle;
    }
}
@-webkit-keyframes borderBlink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: $color-red;
    }
}

.f40 {
    font-size: 40px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}

.txt-regular {
    font-weight: 300 !important;
}
.txt-bold {
    font-weight: 600 !important;
}

.yellow {
    color: $color-yellow !important;
}
.red {
    color: $color-red !important;
}

.normalcase {
    text-transform: none !important;
}

.grey {
    color: $color-grey !important;
}
.grey2 {
    color: $color-grey2 !important;
}
.grey3 {
    color: $color-grey3 !important;
}
.grey4 {
    color: $color-grey4 !important;
}
.grey5 {
    color: $color-grey5 !important;
}

.box-menu {
    position: relative;
    border-bottom: 1px solid #e8e8e8;
    height: 144px;
    padding-top: 30px;

    &.sm {
        background: #f5f5f5 no-repeat top center;
    }

    .navbar-default {
        border: 0;
        background: transparent;
        margin-top: 15px;

        .navbar-nav > li > a {
            color: #222222;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 16px;
        }
    }
    li {
        margin-right: 5px;
    }
    li:last-of-type {
        margin-right: 0;
    }

    li > a .fa {
        margin-left: 7px;
        color: #dcdcdc;
    }
    li > a {
        &:hover {
            .fa {
                color: $color-yellow;
            }
        }
    }
    .navbar-nav {
        position: relative;
        z-index: 2;
    }
    .navbar-nav > li {
        &.active {
            .fa {
                color: $color-yellow;
            }

            &:after {
                content: '';
                display: block;
                height: 2px;
                background: $color-yellow;
                position: absolute;
                bottom: 0;
            }
        }
    }

    .navbar-nav > li > a:not(.btn) {
        border: 1px solid transparent;
    }

    .navbar-default .navbar-nav > .open > a,
    .navbar-default .navbar-nav > .open > a:focus,
    .navbar-default .navbar-nav > .open > a:hover,
    .navbar-nav > li > a:hover:not(.btn) {
        border: 1px solid #ededed;
        background-color: #f3f3f3;
    }

    .navbar-nav li.btn-nav-orange .btn.btn-yellow {
        color: #fff;
        font-weight: 600;
        padding: 10px 20px;
        line-height: 29px;
        height: auto;

        .fa {
            color: #fff;
        }

        &:hover {
            background: $color-yellow;
            color: $color-white;
            padding-bottom: 11px;
            border-bottom-width: 2px;
        }
        &:active {
            padding-bottom: 13px;
            border-bottom-width: 0;
        }
    }

    .navbar-default .navbar-nav .dropdown-menu {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-color: #e3e3e3;
        border-radius: 0;
        padding: 0;

        li {
            border-bottom: 1px solid #e3e3e3;
            margin-right: 0;

            a {
                color: #222222;
                font-weight: 300;
                font-size: 15px;
                padding-top: 10px;
                padding-bottom: 10px;

                &:hover {
                    color: $color-yellow;
                    background: #f8f8f8;
                }
            }
        }
        li:last-of-type {
            border-bottom: 0;
        }
    }

    span.sub-arrow {
        display: none;
    }
}

.row-hr {
    display: block;
    width: 100%;
    height: 1px;
    background: #ebebeb;
}

.mobile-logo {
    position: absolute;
    top: 10px;
    left: 15px;
    display: inline-block;
    width: 34px;
    height: 30px;
    background: transparent no-repeat top center;
}
.mean-container .mean-bar {
    background: #515166;
}
.mean-container .mean-nav ul li a {
    background: #515166;
    &:hover {
        background: #797a97;
    }
}
.mean-container .mean-nav ul li a.mean-expand {
    height: 30px;
    &:hover {
        background: #3d3d4c;
    }
}

form {
    input,
    select,
    textarea {
        &.error {
            -webkit-animation: borderBlink 1s step-end infinite;
            animation: borderBlink 1s step-end infinite;
        }
    }

    .form-error {
        display: block;
        margin-top: 3px;
        color: $color-red;
        font-size: 14px;
    }
}

.box-main {
    display: block;
    width: 100%;
    padding-bottom: 80px;
    background: #fff no-repeat top center;
    background-size: cover;

    h1 {
        font-size: 32px;
        font-weight: 600;
        text-transform: uppercase;
        color: #222222;
        margin-bottom: 40px;
    }
    h2 {
        font-size: 42px;
        color: #5c5c5c;
    }
    h3 {
        font-size: 23px;
        color: #222;
        font-weight: 600;
        margin: 60px 0 30px;
    }

    p {
        line-height: 25px;
    }

    .breadcrumbs {
        margin: 30px 0;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                text-decoration: none;

                .fa {
                    color: $color-yellow;
                    margin-right: 15px;
                    margin-left: 15px;
                }

                a {
                    font-size: 16px;
                    color: $color-default;
                    text-decoration: none;

                    &:hover {
                        color: $color-yellow;
                    }
                }
            }
        }
    }

    &.box-contact {
        .name-header {
            display: block;
            font-size: 23px;
            color: #222;
            font-weight: 600;
            margin-bottom: 25px;
            margin-top: 50px;
        }
        .link-mail {
            text-decoration: none;
            color: $color-default;
            img {
                margin-right: 10px;
            }

            &:hover {
                color: $color-yellow;
            }
        }

        form#form-contact {
            margin-top: 1px;
            background: #f2f2f2;
            padding: 20px 40px;

            h2 {
                margin-top: 0;
                display: block;
                margin-bottom: 30px;

                background: transparent no-repeat center right;
            }

            p {
                line-height: 22px;
            }

            label {
                font-weight: 300;
                margin-top: 7px;
            }

            input[type='text'],
            select,
            textarea {
                background: $color-white;
                border-color: #dbdbdb;
                padding: 10px 20px;
                height: auto;

                &:focus {
                    border-color: $color-yellow;
                }
            }

            select {
                height: 43px;
            }

            button[type='submit'] {
                margin-top: 15px;
            }
        }
    }

    &.box-login {
        padding-bottom: 0;

        form#form-login {
            position: relative;

            .container {
                position: relative;
                z-index: 2;
            }
            &:after {
                content: ' ';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 1;
                background: transparent no-repeat bottom right;
            }

            padding-top: 60px;

            padding-bottom: 80px;
            margin: 0;

            span.h1 {
                display: block;

                strong {
                    font-weight: 600;
                }
                img {
                    vertical-align: middle;
                    position: relative;
                    top: -4px;
                    margin-left: 20px;
                }
            }

            h3 {
                margin-top: 30px;
            }

            label {
                color: #222222;
                font-size: 16px;
                font-weight: 300;
                margin-top: 9px;
            }

            input[type='text'],
            input[type='password'] {
                background: #f8f8f8;
                border-color: #dbdbdb;
                outline: none !important;
                padding: 10px 20px;
                height: auto;

                &:focus,
                &:active {
                    border-color: $color-yellow;
                }
            }

            a.remind {
                display: block;
                font-size: 15px;
                text-decoration: none;
                color: #bdbdbd;
                margin-top: 7px;
                margin-bottom: 10px;
                &:hover {
                    color: $color-yellow;
                }
            }

            div.checkbox {
                font-size: 15px;
                label {
                    padding-left: 0;
                }
                margin-bottom: 20px;

                input[type='checkbox'] {
                    display: none;
                }

                label {
                    color: #8a8a8a;
                    font-size: 15px;
                    font-weight: 300;
                }

                button.checkbox {
                    position: relative;
                    top: -2px;
                    margin-left: 0;
                    display: inline-block;
                    margin-right: 10px;
                    width: 27px;
                    height: 27px;
                    background: #f8f8f8;

                    padding: 0;
                    border: 1px solid #dbdbdb;
                    border-radius: 4px;
                    outline: none;
                    vertical-align: middle;

                    .fa {
                        opacity: 0;
                        color: $color-white;
                    }

                    &.checked {
                        background-color: $color-yellow;
                        border-color: $color-yellow;

                        .fa {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &.box-register {
        background: $color-white no-repeat top center;
        background-size: cover;

        .container.container-reg {
            margin-top: 40px;
        }

        .reg-box {
            display: inline-block;
            width: 398px;
            height: 255px;
            background: no-repeat;
            background-size: cover;
            float: left;
            vertical-align: top;
            padding-top: 40px;
            padding-left: 40px;

            h2 {
                font-size: 35px;
                margin: 0;
                margin-bottom: 40px;
            }

            &.reg-box2 {
                display: none;
            }
        }

        form#form-reg {
            display: inline-block;
            float: left;
            vertical-align: top;
            width: calc(100% - 400px);
            padding-left: 70px;

            > h2 {
                font-size: 40px;
                margin-top: 0;
                margin-bottom: 30px;

                &:after {
                    content: '';
                    width: 34px;
                    height: 36px;
                    float: right;
                }
            }

            h3 {
                &.icon-reg2 {
                    &:after {
                        content: '';
                        width: 26px;
                        height: 32px;
                        float: right;
                    }
                }

                &.icon-reg3 {
                    &:after {
                        content: '';
                        width: 29px;
                        height: 25px;
                        float: right;
                    }
                }
            }

            .form-group {
                margin-bottom: 20px;

                .req {
                    position: absolute;
                    color: $color-yellow;
                    top: 15px;
                    right: -25px;
                }
            }

            .form-group:last-of-type {
                margin-bottom: 0;
                margin-top: 50px;

                .ob {
                    display: block;
                    margin-top: 10px;
                    text-align: right;
                }
            }

            label {
                color: #222222;
                font-size: 16px;
                font-weight: 300;
                margin-top: 15px;
            }

            input[type='text'],
            select,
            input[type='password'] {
                background: #f8f8f8;
                border-color: #dbdbdb;
                outline: none !important;
                padding: 10px 20px;
                height: auto;

                &:focus,
                &:active {
                    border-color: $color-yellow;
                }
            }

            select {
                height: 43px;
            }

            div.checkbox {
                font-size: 15px;
                label {
                    padding-left: 0;
                }
                margin-bottom: 0;
                padding-top: 0;

                input[type='checkbox'] {
                    display: none;
                }

                label {
                    color: #8a8a8a;
                    font-size: 15px;
                    font-weight: 300;
                    margin-top: 0;
                }

                button.checkbox {
                    position: relative;
                    top: -2px;
                    margin-left: 0;
                    display: inline-block;
                    margin-right: 10px;
                    width: 27px;
                    height: 27px;
                    background: #f8f8f8;

                    padding: 0;
                    border: 1px solid #dbdbdb;
                    border-radius: 4px;
                    outline: none;
                    vertical-align: middle;

                    .fa {
                        opacity: 0;
                        color: $color-white;
                    }

                    &.checked {
                        background-color: $color-yellow;
                        border-color: $color-yellow;

                        .fa {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    &.box-product {
        background: $color-white;
        padding-bottom: 2px;

        .tabpanel-product {
            margin-top: 80px;
        }

        .product-packing-img {
            display: block;
            margin-top: 110px;
            img {
                max-width: 100%;
            }
        }

        .product-info {
            display: block;
            margin-top: 60px;

            .product-info-img,
            .product-info-sizes {
                display: inline-block;
                vertical-align: top;
                font-size: 16px;
                margin-left: 30px;
            }

            .product-info-sizes {
                > span {
                    display: block;
                    font-weight: 600;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $color-yellow;
                }

                ul {
                    display: block;
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    margin-top: 10px;
                    margin-bottom: 3px;
                    li {
                        display: block;

                        span {
                            margin-left: 7px;
                        }
                    }
                }
            }
        }

        .api-box {
            display: block;
            width: 100%;
            background: #f6f6f6;
            border: 1px solid #d5d5d5;
            padding: 30px;
            margin-bottom: 20px;
            margin-top: 15px;

            section {
                display: block;
                padding-left: 40px;

                h3 {
                    margin: 5px 0 10px;
                    font-size: 16px;
                    font-weight: 600;
                }

                ul {
                    list-style-type: square;

                    li {
                        color: $color-yellow;

                        span {
                            color: $color-default;
                            word-break: break-all;
                        }
                    }
                }
            }
        }

        .demo-box {
            display: block;
            width: 100%;
            background: #f6f6f6;
            border: 1px solid #d5d5d5;
            padding: 30px;
            margin-bottom: 20px;
            margin-top: 15px;

            h2 {
                color: #5c5c5c;
                margin-bottom: 30px;
            }
            h3 {
                font-weight: 300;
                font-size: 36px;
                color: #4c4c4c;
            }

            .containers {
                display: block;

                span.th {
                    display: none;
                }

                input[type='text']:focus {
                    border-color: $color-yellow;
                }

                div.table {
                    display: table;
                    table-layout: fixed;

                    div.th {
                        display: table-cell;
                        font-weight: normal;
                        font-size: 16px;
                        text-align: left;
                        padding-bottom: 10px;
                        line-height: 17px;
                        padding-top: 10px;
                    }

                    div.tr {
                        display: table-row;
                    }

                    div.td {
                        display: table-cell;
                        width: 16.66666%;
                        padding-right: 15px;
                        padding-bottom: 10px;
                    }
                }

                table {
                    table-layout: fixed;

                    th {
                        font-weight: normal;
                        font-size: 16px;
                        text-align: left;
                        padding-bottom: 10px;
                        line-height: 17px;
                        padding-top: 10px;
                    }

                    td {
                        width: 16.66666%;
                        padding-right: 15px;
                        padding-bottom: 10px;
                    }
                }
            }

            .containers.products {
                div.table {
                    div.th {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    div.td {
                        width: 12.5%;
                    }
                }

                table {
                    th {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                    td {
                        width: 12.5%;
                    }
                }

                div.checkbox {
                    top: 0;
                }
            }

            #btn-add-prod,
            #btn-add-container {
                margin-top: 30px;
                outline: none;
            }

            .list-colors {
                list-style-type: none;
                padding: 0;
                margin: 0;
                font-size: 13px;
                display: none;

                input[type='text'] {
                    display: block;
                    width: 100%;
                    border: 0;
                    border-radius: 3px;
                }

                .color-rgb {
                    display: block;
                }

                li {
                    display: inline-block;
                    width: 12.5%;
                    padding-left: 2px;
                    vertical-align: top;
                }
                li:first-of-type {
                    padding-left: 0;
                }
            }

            .containers {
                input[type='text'] {
                    background: $color-white;
                    min-width: 50px;
                    max-width: 100%;
                    outline: none;
                    margin-right: 10px;
                }

                button.del-container,
                button.del-prod {
                    background: transparent no-repeat center center;
                    outline: none;
                    width: 20px;
                    height: 28px;
                    border: 0;
                    padding: 5px;
                    margin-left: 5px;
                }

                div.checkbox {
                    font-size: 15px;
                    label {
                        padding-left: 0;
                    }
                    margin: 0;

                    input[type='checkbox'] {
                        display: none;
                    }

                    label {
                        color: #8a8a8a;
                        font-size: 15px;
                        font-weight: 300;
                    }

                    button.checkbox {
                        position: relative;
                        top: -2px;
                        margin: 0;
                        display: inline-block;
                        width: 27px;
                        height: 27px;
                        background: #f8f8f8;

                        padding: 0;
                        border: 1px solid #dbdbdb;
                        border-radius: 4px;
                        outline: none;
                        vertical-align: middle;

                        .fa {
                            opacity: 0;
                            color: $color-white;
                        }

                        &.checked {
                            background-color: $color-yellow;
                            border-color: $color-yellow;

                            .fa {
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            button#btn-show-colors {
                background: transparent;
                border: 0;
                color: $color-yellow;
                font-size: 20px;
                font-weight: 300;
                vertical-align: middle;
                outline: none;
            }

            button[type='submit'] {
                margin-top: 50px;
            }
        }

        .demo-box-result,
        .demo-box {
            h3 {
                font-weight: 300;
                font-size: 36px;
                color: #4c4c4c;
            }
        }

        .demo-box-result {
            display: block;

            .img-box-result {
                max-width: 100%;
                position: relative;
                top: -30px;
            }

            .content {
                display: block;
                border: 1px solid #d5d5d5;
                border-top: 0;
                padding: 20px 20px 60px 20px;
                background: #f5f5f5;

                h3 {
                    margin-top: 20px;
                }
                h4 {
                    display: block;
                    font-weight: 600;
                    font-size: 20px;
                    margin: 0;
                    margin-bottom: 20px;
                    color: #222222;
                }

                table.p-sizes {
                    table-layout: fixed;

                    td + td {
                        padding-left: 20px;
                    }
                }

                table.p-info {
                    table-layout: fixed;
                    td + td {
                        padding-left: 20px;
                    }

                    td {
                        padding-bottom: 4px;
                        padding-top: 4px;
                        border-bottom: 1px solid #d7d7d7;
                    }
                    tr:last-of-type td {
                        border: 0;
                    }
                }

                ul.packing-info {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    li {
                        display: inline-block;
                        width: 50%;
                        border-right: 1px solid #d7d7d7;
                        padding-right: 20px;

                        img {
                            margin-right: 5px;
                        }
                        img:last-of-type {
                            margin-right: 0;
                        }
                    }
                    li:last-of-type {
                        border: 0;
                        padding-right: 0;
                        padding-left: 20px;
                    }
                }
            }

            .header {
                display: block;
                background: $color-yellow;
                padding: 20px;

                h2 {
                    position: relative;
                    display: inline-block;
                    color: $color-white;
                    font-size: 40px;
                    text-transform: none;
                    padding-right: 20px;

                    &:after {
                        display: inline-block;
                        position: absolute;
                        content: '';
                        width: 1px;
                        height: 65px;
                        background: rgb(255, 255, 255);
                        background: rgba(255, 255, 255, 0.17);
                        right: -10px;
                        top: -8px;
                    }

                    &:before {
                        display: inline-block;
                        position: absolute;
                        content: '';
                        width: 42px;
                        height: 44px;
                        background: transparent;
                        right: -72px;
                        top: -4px;
                    }
                }

                .box-respond {
                    margin-top: 18px;
                    float: right;
                    display: block;
                    background: #f7a21b;
                    padding: 10px 20px;
                    color: $color-white;

                    span {
                        color: #976e2e;
                        font-size: 18px;
                    }
                }
            }
        }

        .product-tutorial {
            background: $color-yellow no-repeat bottom center;
            padding-top: 50px;
            padding-bottom: 80px;
            background-size: cover;

            .tutorial-img {
                max-width: 100%;
            }
        }
    }

    &.box-prices {
        .select-currency {
            display: block;
            margin-top: 30px;
        }
        .flag-box {
            display: inline-block;
            padding: 2px;
            border: 1px solid #fff;
            vertical-align: middle;
            margin-left: 7px;

            &.active {
                border-color: #e3e3e3;

                .btn-flag {
                    &.flag-1 {
                        background-position: 0 0;
                    }
                    &.flag-2 {
                        background-position: -48px 0;
                    }
                    &.flag-3 {
                        background-position: -100px 0;
                    }
                    &.flag-4 {
                        background-position: -149px 0;
                    }
                }
            }

            .btn-flag {
                display: block;
                width: 33px;
                height: 24px;
                background: transparent;
                background-repeat: no-repeat;
                border: 0;
                outline: none;

                &:hover {
                    &.flag-1 {
                        background-position: 0 0;
                    }
                    &.flag-2 {
                        background-position: -48px 0;
                    }
                    &.flag-3 {
                        background-position: -100px 0;
                    }
                    &.flag-4 {
                        background-position: -149px 0;
                    }
                }

                &.flag-1 {
                    background-position: 0 -24px;
                }
                &.flag-2 {
                    background-position: -48px -24px;
                }
                &.flag-3 {
                    background-position: -100px -24px;
                }
                &.flag-4 {
                    background-position: -149px -24px;
                }
            }
        }

        .pricelist-container {
            display: block;
            margin-top: 40px;
            margin-bottom: 40px;
        }
        .pricelist {
            display: none;
            &.active {
                display: block;
            }

            ul {
                display: block;
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    border: 1px solid #ededed;
                    width: 16.66666666666667%;

                    .title {
                        display: block;
                        font-size: 22px;
                        color: #868686;
                        text-align: center;
                        padding: 15px 15px;
                        background: #e8e8e8;
                    }

                    .price {
                        display: block;
                        font-size: 54px;
                        color: $color-yellow;
                        text-align: center;
                        font-weight: 600;
                        padding: 40px 15px 30px;
                        span {
                            font-size: 18px;
                            color: #5c5c5c;
                            margin-left: 3px;
                        }
                    }

                    .button {
                        display: block;
                        padding: 15px 15px 30px;

                        .btn {
                            width: 100%;
                            font-size: 11px;
                        }
                    }

                    &.bestchoice {
                        top: -15px;
                        background: #f9f9f9;
                        padding-bottom: 30px;

                        .title {
                            background: $color-yellow;
                            color: $color-white;
                        }
                    }

                    ul.options {
                        border: 0;
                        display: block;
                        padding: 0;
                        margin: 0;
                        list-style-type: none;

                        li {
                            &:before {
                                position: absolute;
                                top: 12px;
                                left: 15px;
                                display: block;
                                content: '';
                                width: 14px;
                                height: 10px;
                                background: transparent no-repeat;
                            }
                            position: relative;
                            width: 100%;
                            display: block;
                            border: 0;
                            border-bottom: 1px solid #ededed;
                            padding: 6px 15px 6px 35px;
                            font-size: 14px;
                            color: #868686;
                            line-height: 20px;

                            strong {
                                font-weight: 600;
                            }

                            &:last-of-type {
                                border: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.box-top {
    display: block;
    width: 100%;
    height: 710px;
    background: #fcfcfc no-repeat top center;
    position: relative;
    background-size: cover;

    .box-banner {
        position: relative;
        height: 556px;
        overflow: hidden;

        .container {
            position: relative;
        }

        .banner-image {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;

            &.banner-image2 {
                display: none;
            }
        }

        .container .row .col-xs-6 {
            z-index: 2;
        }
        h2 {
            margin-top: 100px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 42px;
            margin-bottom: 0;

            span {
                color: #717171;
                display: block;
            }
        }
        h3 {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 52px;
            margin-top: 0;
            margin-bottom: 40px;
        }

        .btn {
            margin-top: 50px;
        }
    }
}

.btn {
    text-transform: uppercase;
    height: 43px;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 29px;

    outline: none !important;
    &.btn-big {
        padding: 15px 30px;
        height: auto;

        &:hover {
            padding-bottom: 16px !important;
        }
        &:active {
            padding-bottom: 18px !important;
        }
    }

    .fa {
        margin-left: 15px;
    }

    &.btn-white {
        color: $color-yellow;
        background: $color-white;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 3px solid $color-grey;

        &:hover {
            border-bottom-width: 2px;
        }
        &:active {
            padding-bottom: 13px;
            border-bottom-width: 0;
        }
    }

    &.btn-yellow {
        color: $color-white;
        background: $color-yellow;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 3px solid $color-yellow2;

        &:hover {
            padding-bottom: 11px;
            border-bottom-width: 2px;
        }
        &:active {
            padding-bottom: 13px;
            border-bottom-width: 0;
        }
    }

    &.btn-grey {
        color: $color-white;
        background: #999999;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 3px solid #6b6b6b;

        &:hover {
            border-bottom-width: 2px;
        }
        &:active {
            padding-bottom: 13px;
            border-bottom-width: 0;
        }
    }
}

.tabpanel-yellow {
    position: relative;

    .yellow-line {
        position: absolute;
        width: 100%;
        height: 1px;
        display: block;
        background: $color-yellow;
        left: 0;
        top: 46px;
    }
}

.tabpanel-grey {
    position: relative;

    .grey-line {
        position: absolute;
        width: 100%;
        height: 1px;
        display: block;
        background: $color-grey5;
        left: 0;
        top: 46px;
    }
}

.box-hp-main {
    display: block;
    margin: 70px 0 0 0;
    background: #fff no-repeat bottom center;
    min-height: 831px;
    background-size: cover;

    h2 {
        margin-bottom: 60px;
    }

    .box-hp-clients {
        display: block;
        width: 100%;
        background: #fff;
        background: rgba(255, 255, 255, 0.3);
        border-top: 1px solid #e8e8e8;
        padding: 60px 0;

        span {
            text-transform: uppercase;
            font-size: 27px;
            font-weight: 600;
            color: #222;
            margin-top: 10px;
            display: inline-block;
        }
    }

    .col-xs-4 img {
        max-width: 100%;
    }
}

.nav.nav-tabs.nav-yellow {
    //border-bottom-color: $color-yellow;
    border-bottom: 0;
    & > li a {
        font-size: 18px;
        font-weight: 600;
        color: $color-grey3;
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
            color: $color-yellow;
            background: transparent;
            border-bottom-color: $color-yellow;
        }
    }

    & > li.active {
        a {
            color: $color-yellow;
            border-top-color: $color-yellow;
            border-left-color: $color-yellow;
            border-right-color: $color-yellow;
        }
    }
}

.nav.nav-tabs.nav-grey {
    border-bottom-color: $color-grey5;

    & > li a {
        font-size: 18px;
        font-weight: 600;
        color: $color-grey3;
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
            color: $color-grey6;
            background: transparent;
            border-bottom-color: $color-grey5;
        }
    }

    & > li.active {
        a {
            color: $color-grey6;
            border-top-color: $color-grey5;
            border-left-color: $color-grey5;
            border-right-color: $color-grey5;
        }
    }
}

.tab-pane {
    padding: 70px 0 40px;

    h2 {
        text-transform: uppercase;
    }

    &.tab-pane-tutorial {
        padding: 0;
        padding-top: 1px;
    }
}

.tab-content {
    a {
        color: $color-yellow;
        text-decoration: none;
        &:hover {
            color: $color-yellow2;
        }
    }
}

.box-newsletter {
    display: block;
    width: 100%;
    height: 121px;
    background: #ffa71c no-repeat top center;
    color: $color-white;
    background-size: cover;

    .icon-newsletter {
        display: inline-block;
        vertical-align: middle;
        width: 39px;
        height: 47px;
        background: no-repeat;
        background-size: cover;
        margin-right: 15px;
    }

    .form-newsletter {
        margin-top: 40px;
        display: block;
        text-transform: none;
        font-size: 24px;
        font-weight: 600;

        input[type='text'] {
            font-size: 14px;
            height: 43px;
            outline: 0;
            background: #a56909;
            background: rgba(165, 105, 9, 0.4);
            color: $color-white;
            font-size: 14px;
            font-weight: 300;
            padding-left: 30px;
            padding-right: 30px;
            border: 0;
            margin-left: 15px;
            margin-right: 10px;
            width: 300px;

            &::-webkit-input-placeholder {
                color: $color-white;
            }
            &:-moz-placeholder {
                color: $color-white;
            }
            &::-moz-placeholder {
                color: $color-white;
            }
            &:-ms-input-placeholder {
                color: $color-white;
            }

            &.error {
                border: 1px solid $color-red;
            }
        }
        input[type='text']:focus {
            outline: 0;
        }

        button[type='submit'] {
            span.ok {
                display: none;
            }
            span.loader {
                display: none;
            }
        }
    }
}

footer {
    background: #3d3d3d no-repeat bottom right;
    padding: 40px 0 30px;
    color: $color-grey2;

    .row.row-infos {
        margin-top: 70px;
        margin-bottom: 50px;

        a {
            text-decoration: none;
            display: block;
            font-weight: 300;
            font-size: 16px;
            margin: 0;
            margin-bottom: 30px;
            color: $color-grey2;

            &:hover {
                color: $color-yellow;
            }
        }
    }

    .row.row-copy {
        color: $color-white;

        a {
            margin-left: 15px;
        }
    }

    ul.nav-socials {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            display: inline-block;
            margin-right: 10px;
            a {
                display: block;
                text-indent: -99999px;
                width: 46px;
                height: 46px;
                background-size: cover;
            }

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    nav.nav-footer {
        ul {
            list-style-type: none;
            display: block;
            padding: 0;
            margin: 0;

            li {
                display: inline-block;
                margin-right: 30px;

                a {
                    color: $color-grey2;
                    text-transform: uppercase;
                    font-size: 16px;
                    text-decoration: none;

                    &:hover {
                        color: $color-white;
                    }
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    h3 {
        font-weight: 300;
        font-size: 16px;
        margin: 0;
        margin-bottom: 30px;
        color: $color-grey2;
    }

    span.footer-title {
        font-size: 13px;
        display: block;
    }
    p {
        font-weight: 300;
        font-size: 11px;
    }
}

.menu-mobile {
    display: none;
}
.product-tutorial .container-m {
    display: none;
}

.img-paypal {
    max-width: 100%;
}

@media only screen and (max-width: 1680px) {
    .box-top .box-banner .banner-image {
        right: -200px;
    }
}
@media only screen and (max-width: 1500px) {
    .box-top .box-banner .banner-image {
        right: -400px;
    }
}

@media only screen and (max-width: 1280px) {
}
@media only screen and (max-width: 1199px) {
    .box-main.box-login form#form-login:after {
        opacity: 0.5;
    }

    .box-top .box-banner .banner-image {
        right: -600px;
    }

    .box-menu .navbar-default .navbar-nav > li > a,
    .box-menu .navbar-default .navbar-nav .dropdown-menu li a,
    .nav.nav-tabs.nav-grey > li a {
        font-size: 12px;
    }

    .nav.nav-tabs.nav-yellow > li a {
        font-size: 13px;
    }

    .box-banner {
        .container .col-xs-6 {
            width: 70%;
        }
    }

    .tabpanel-yellow .yellow-line {
        top: 39px;
    }
    .tabpanel-grey .grey-line {
        top: 38px;
    }

    footer {
        > .container > .row > .col-xs-9 {
            width: 73%;
        }
        > .container > .row > .col-xs-3 {
            width: 27%;
        }

        .row.row-infos {
            .col-xs-3 {
                width: 25%;
            }
        }
    }

    .box-hp-main .box-hp-clients span {
        margin-top: 0;
    }

    #form-product .demo-box .col-xs-5 {
        display: block;
        width: 100%;
    }
    #form-product .demo-box .col-xs-7 {
        display: block;
        width: 100%;
    }

    .box-main.box-product .demo-box-result .header h2 {
        font-size: 25px;
    }
    .box-main.box-product .demo-box-result .header h2:after {
        top: -18px;
    }
    .box-main.box-product .demo-box-result .header h2:before {
        top: -15px;
    }
    .box-main.box-product .demo-box-result .header .box-respond {
        margin-top: 10px;
    }

    .box-main.box-prices .pricelist ul li {
        width: 33%;
    }

    .box-main.box-prices .pricelist ul li.bestchoice {
        top: 0;
        padding-bottom: 0;
    }
}

@media only screen and (max-width: 1024px) {
}

@media screen and (max-width: 991px) {
    h2 {
        font-size: 35px;
    }
    .box-top .box-banner {
        overflow: visible;
    }
    .box-top .box-banner .banner-image {
        opacity: 0.5;
        right: 0;
    }
    .box-top .box-banner h2 {
        font-size: 38px;
    }
    .box-top .box-banner h3 {
        font-size: 44px;
    }

    .box-hp-main .box-hp-clients span {
        margin-top: 10px;
    }

    footer {
        > .container > .row:first-of-type {
            .col-xs-9 {
                width: 100%;
                display: block;
                margin-bottom: 20px;
            }
            .col-xs-3 {
                width: 100%;
                display: block;
            }
        }

        .row.row-infos .col-xs-3 {
            width: 50%;
            margin-bottom: 20px;
        }
    }

    .box-banner .container .col-xs-6 {
        width: 100%;
    }

    .tabpanel-yellow .yellow-line,
    .tabpanel-grey .grey-line {
        display: none;
    }

    .nav-tabs {
        display: block;
        width: 100%;
        border-bottom: 0 !important;
        > li {
            width: 100%;
            display: block;
            border-radius: 0;

            > a {
                border-radius: 0;
            }
        }
    }

    .nav.nav-tabs.nav-yellow > li a:hover {
        border-bottom-color: #eee;
    }
    .nav.nav-tabs.nav-yellow > li.active a {
        border-bottom: 1px solid $color-yellow;
    }

    .nav.nav-tabs.nav-grey > li.active a {
        border-bottom-color: #eaeaea;
    }

    .tab-pane h2 {
        font-size: 35px !important;
    }

    .box-main h2 {
        font-size: 35px;
    }

    .box-main.box-contact .col-xs-5,
    .box-main.box-contact .col-xs-7 {
        width: 100%;
        display: block;
    }
    .box-main.box-contact form#form-contact {
        margin-top: 40px;
    }

    .box-main.box-register .reg-box {
        display: none;
    }
    .box-main.box-register .reg-box.reg-box2 {
        float: none;
        display: block;
        margin-top: 60px;
    }

    .box-main.box-register form#form-reg {
        float: none;
        display: block;
        width: 100%;
        padding-left: 0;
        margin-top: 30px;
    }
    .box-main.box-product .demo-box .list-colors li {
        width: 25%;
        margin-bottom: 20px;
    }

    .box-main.box-product .demo-box-result .header h2 {
        font-size: 25px !important;
    }
    .box-main.box-product .demo-box-result .header h2:after {
        top: -18px;
    }
    .box-main.box-product .demo-box-result .header h2:before {
        top: -15px;
    }
    .box-main.box-product .demo-box-result .header .box-respond {
        margin-top: 10px;
    }

    .box-main.box-product .demo-box-result {
        .content {
            .row:nth-of-type(2) {
                .col-xs-2 {
                    width: 100%;
                    display: block;
                    margin-bottom: 20px;
                }
                .col-xs-4 {
                    width: 100%;
                    display: block;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .box-main.box-product .demo-box-result .img-box-result {
        display: none;
    }

    .box-main.box-product .demo-box-result .content ul.packing-info li {
        width: 100%;
        display: block;
        border: 0;
        padding: 0;
        margin-bottom: 20px;
    }
    .box-main.box-product .demo-box-result .content ul.packing-info li img {
        margin-bottom: 15px;
    }
    .box-main.box-product
        .demo-box-result
        .content
        ul.packing-info
        li:last-of-type {
        padding: 0;
    }

    .box-menu {
        display: none;
    }

    .box-top {
        position: relative;
        height: calc(710px - 144px);
    }

    .box-hp-main .col-xs-8,
    .box-hp-main .col-xs-7 {
        width: 100%;
        z-index: 2;
    }
    .box-hp-main .col-xs-4,
    .box-hp-main .col-xs-5 {
        position: absolute;
        width: 100%;
        text-align: right;
        z-index: 1;
        img {
            opacity: 0.3;
            max-width: 100%;
        }
    }

    .tab-content .tab-pane .row {
        position: relative;
    }
}

@media screen and (max-width: 767px) {
    .box-top .box-banner h2 {
        margin-top: 70px;
    }
    .box-hp-main {
        margin: 30px 0 0 0;
    }

    .box-hp-main .box-hp-clients {
        padding: 30px 0;
    }

    .box-newsletter {
        background: $color-yellow;
        height: auto;

        .form-newsletter {
            text-align: center;

            input[type='text'] {
                width: 100%;
                margin: 20px 0;
            }

            button[type='submit'] {
                width: 100%;
            }
        }
    }

    .box-main h2 {
        font-size: 26px !important;
    }
    .box-main.box-register form#form-reg .form-group .req {
        right: 4px;
    }

    .box-main.box-product .demo-box-result .header h2 {
        font-size: 20px !important;
    }
    .box-main.box-product .product-info .product-info-img {
        display: block;
        margin-bottom: 20px;
    }

    .box-main.box-product .demo-box-result .header h2:before,
    .box-main.box-product .demo-box-result .header h2:after {
        display: none;
    }
}

@media screen and (max-width: 640px) {
    .box-main.box-product .container .col-xs-8 {
        width: 100%;
        display: block;
        z-index: 2;
    }
    .box-main.box-product .container:nth-of-type(3) .col-xs-4 {
        z-index: 1;
        position: absolute;
        width: 100%;
        text-align: right;
        img {
            opacity: 0.3;
        }
    }

    footer {
        nav.nav-footer {
            li:last-of-type {
                display: block;
                width: 100%;
                a {
                    width: 100%;
                    display: block;
                    margin-top: 20px;
                }
            }
        }
    }

    .box-top .box-banner h2 {
        font-size: 30px;
    }
    .box-top .box-banner h3 {
        font-size: 35px;
    }

    .box-hp-main .box-hp-clients {
        .col-xs-2,
        .col-xs-10 {
            width: 100%;
            display: block;
        }
    }

    footer .row.row-copy {
        .col-xs-6 {
            width: 100%;
            display: block;
            text-align: center !important;
            margin-bottom: 20px;
        }
    }

    footer > .container > .row:first-of-type .col-xs-3 {
        text-align: center;
    }

    .box-main.box-product .demo-box .containers input[type='text'] {
        min-width: 0;
        padding-left: 5px;
        padding-right: 5px;
    }

    .box-main.box-product .demo-box .containers div.table div.td {
        padding-right: 5px;
    }
    .box-main.box-product .demo-box .list-colors li {
        width: 50%;
    }

    .box-main.box-product .demo-box-result .header .col-xs-6 {
        display: block;
        width: 100%;
    }
    .box-main.box-product .demo-box-result .header .box-respond {
        float: none;
        width: 100%;
    }

    .box-main.box-prices .pricelist ul li {
        width: 50%;
    }

    .box-main.box-product .demo-box .containers div.table div.tr:first-of-type {
        display: none;
    }
    .box-main.box-product .demo-box .containers {
        span.th {
            display: block;
        }

        input[type='text'] {
            width: 100%;
            display: block;
        }
        div.table div.tr div.td:last-of-type {
            text-align: center;
        }
    }

    .box-main.box-product .demo-box .containers div.table {
        display: block !important;

        div.tr,
        div.td {
            display: block !important;
            width: 100% !important;
        }
    }
    .box-main.box-product .demo-box .containers div.table div.tr:first-of-type {
        display: none !important;
    }

    .box-main.box-product .demo-box .containers div.table div.tr {
        border-bottom: 1px solid $color-grey5;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .box-main.box-product .demo-box .containers div.table div.tr:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
        padding-bottom: 0;
    }

    .box-main.box-product .demo-box .containers div.table div.td,
    .box-main.box-product .demo-box .containers.products div.table div.td {
        text-align: left;
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 480px) {
    footer .row.row-infos .col-xs-3 {
        width: 100%;
        display: block;
    }

    footer {
        nav.nav-footer {
            li {
                display: block;
                width: 100%;
                margin-bottom: 10px;

                a {
                    display: block;
                    width: 100%;
                }
            }
        }
    }

    .box-main.box-product .demo-box .list-colors li {
        width: 100%;
    }
    .box-main.box-contact form#form-contact h2 {
        background: none;
    }

    .box-main.box-register .reg-box {
        margin: 0;
        width: 100%;
    }
    .box-main.box-register .reg-box h2 {
        font-size: 30px;
    }

    .product-tutorial .container-d {
        display: none;
    }

    .box-main.box-product .product-tutorial {
        padding-top: 0;
        padding-bottom: 0;
        background: $color-yellow Result('../images/tutorialmbg.png') no-repeat
            top center;
    }

    .product-tutorial .container-m {
        display: block;
        width: 100%;
        background: transparent Result('../images/dots.png') repeat-y top center;
        padding-top: 50px;
        padding-bottom: 80px;

        ul {
            display: block;
            list-style-type: none;
            padding: 0;
            margin: 0;

            li {
                display: block;
                width: 100%;
                text-align: center;
                font-size: 17px;
                color: $color-white;

                img {
                    display: block;
                    margin-bottom: 15px;
                    margin: 20px auto;
                }

                margin-bottom: 80px;
            }
        }
    }

    .box-main.box-prices .pricelist ul li {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    .colorpicker {
        display: none !important;
    }
}
@media screen and (max-width: 320px) {
}

.owl-controls {
    display: none !important;
}
